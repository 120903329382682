import React, { useEffect, useState } from 'react';
import { Button, IconButton } from '@instructure/ui-buttons';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { View } from '@instructure/ui-view';
import { Text } from '@instructure/ui-text';
import { List } from '@instructure/ui-list';
import { IconAdminLine, IconEyeLine } from '@instructure/ui-icons';
import { Flex } from '@instructure/ui-flex';
import TextField from 'components/IUTextField';
import * as Routes from 'Routes';

interface IUPasswordFieldsProps {
  backToLogin?: boolean;
  buttonText?: string;
  panelMinHeight?: string;
  submitButton?: boolean;
}

const IUPasswordFields: React.FC<IUPasswordFieldsProps> = ({
  backToLogin = false,
  buttonText = 'Set Password',
  panelMinHeight = '32em',
  submitButton = true,
}) => {
  const [password, updatePassword] = useState<string>('');
  const [confirmPassword, updateConfirmPassword] = useState<string>('');
  const [checkLength, setCheckLength] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwordValid, setPasswordValid] = useState<boolean>(false);
  const [isNextStepAllowed, setIsNextStepAllowed] = useState<boolean>(false);

  const validatePassword = (newPassword: string, newConfirmPassword: string, recentPassword: string): boolean => {
    const lengthValid = newPassword.length >= 12 && newPassword.length <= 32;
    const notRecentPassword = newPassword !== recentPassword;
    const hasUppercase = /[A-Z]/.test(newPassword);
    const hasLowercase = /[a-z]/.test(newPassword);
    const hasDigit = /\d/.test(newPassword);
    const hasSymbol = /[!@#$%^&*0]/.test(newPassword);
    return lengthValid && notRecentPassword && hasUppercase && hasLowercase && hasDigit && hasSymbol;
  };

  useEffect(() => {
    setPasswordValid(validatePassword(password, confirmPassword, ''));
  }, [password]);
  useEffect(() => {
    setIsNextStepAllowed(passwordValid && password === confirmPassword);
  }, [password, confirmPassword, passwordValid]);

  const renderFields = () => {
    return (
      <>
        <View
          as="div"
          display="inline-block"
          margin="medium none none none"
          padding="small"
          background="secondary"
          borderWidth="small"
          borderColor="primary"
        >
          <Text weight="bold">Password Criteria</Text>
          <List margin="none">
            {/* @ts-ignore */}
            <List.Item>Must be at least 12-32 characters in length.</List.Item>
            {/* @ts-ignore */}
            <List.Item>Cannot be the same as your most recent password.</List.Item>
            {/* @ts-ignore */}
            <List.Item>
              Must include at least 1 uppercase character, 1 lowercase character, 1 digit and 1 symbol (only !@#$%^&*0
              symbols).
              {/* @ts-ignore */}
            </List.Item>
          </List>
        </View>
        {/* Maybe the correct thing here is to remove the form field group. */}
        <FormFieldGroup description="">
          <TextField
            data-node="password"
            onChange={e => updatePassword(e.target.value)}
            onFocus={() => setCheckLength(false)}
            onBlur={() => setCheckLength(true)}
            name="user[password]"
            value={password}
            id="user_password"
            label="New Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            autoComplete="new-password"
            variant="filled"
            error={checkLength && !passwordValid}
            helperText={checkLength && !passwordValid ? "Password doesn't match the expected format" : 'Required*'}
            renderAfterInput={
              <IconButton
                /* @ts-ignore */
                onClick={() => setShowPassword(!showPassword)}
                shape="circle"
                size="small"
                withBorder={false}
                withBackground={false}
                screenReaderLabel="Show password"
                renderIcon={showPassword ? IconAdminLine : IconEyeLine}
                data-node="login-show-password-button"
              />
            }
          />
          <TextField
            data-node="confirm_password"
            onChange={e => updateConfirmPassword(e.target.value)}
            name="user[password_confirmation]"
            value={confirmPassword}
            id="user_confirm_password"
            label="Confirm New Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            autoComplete="new-password"
            variant="filled"
            error={!!confirmPassword && confirmPassword !== password}
            helperText={!!confirmPassword && confirmPassword !== password ? 'Passwords must match' : 'Required*'}
            renderAfterInput={
              <IconButton
                /* @ts-ignore */
                onClick={() => setShowPassword(!showPassword)}
                shape="circle"
                size="small"
                withBorder={false}
                withBackground={false}
                screenReaderLabel="Show password"
                renderIcon={showPassword ? IconAdminLine : IconEyeLine}
                data-node="login-show-password-button"
              />
            }
          />
          {backToLogin && (
            <div>
              <Button variant="link" href={Routes.new_user_session_path()}>
                Back to Login
              </Button>
            </div>
          )}
        </FormFieldGroup>
      </>
    );
  };

  if (submitButton) {
    return (
      <>
        {/* @ts-ignore */}
        <Flex.Item shouldShrink shouldGrow>
          <View as="div" margin="none" minHeight={panelMinHeight}>
            {renderFields()}
          </View>
          {/* @ts-ignore */}
        </Flex.Item>
        {/* @ts-ignore */}
        <Flex.Item>
          <Flex as="div" margin="none" direction="row">
            {/* @ts-ignore */}
            <Flex.Item shouldShrink shouldGrow />
            {/* @ts-ignore */}
            <Flex.Item>
              <Button
                data-node="password_button"
                interaction={!isNextStepAllowed ? 'disabled' : 'enabled'}
                color="primary"
                margin="xx-small"
                size="medium"
                type="submit"
              >
                {buttonText}
              </Button>
              {/* @ts-ignore */}
            </Flex.Item>
          </Flex>
          {/* @ts-ignore */}
        </Flex.Item>
      </>
    );
  }

  return renderFields();
};

export default IUPasswordFields;
