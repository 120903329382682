import React, { useEffect } from 'react';
import { Text } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import { Helmet } from 'react-helmet';
import { useResponsiveContext } from 'hooks/useResponsiveContext';
import TextField from 'components/IUTextField';
import { isBlank } from 'utils/Utils';
import roles from '../../../sharedComponents/IURoleRender/roles';
import IUPasswordFields from '../../../sharedComponents/IUPasswordFields';

interface AccountCreationProps {
  selectedRole: string | null;
  user: any;
  errors: any;
  setIsNextStepAllowed: (allowed: boolean) => void;
  setNextStepLabel: (label: string) => void;
  onChange: (label: string) => (e: any) => void;
  showEmail?: boolean;
  showPassword?: boolean;
}

const AccountCreation: React.FC<AccountCreationProps> = ({
  selectedRole,
  errors,
  user,
  onChange,
  showEmail,
  showPassword = false,
  setIsNextStepAllowed,
  setNextStepLabel,
}) => {
  const currentRole = roles.find(role => role.id === selectedRole);
  const { isMobile, isTablet } = useResponsiveContext();
  const minWidth = isMobile || isTablet ? '100%' : '35em';

  const isEmptyObject = (obj: object): boolean => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  useEffect(() => {
    setIsNextStepAllowed(!!user.firstName && !!user.lastName && !!user.email && isEmptyObject(errors));
    setNextStepLabel('Submit');
  }, [selectedRole, setIsNextStepAllowed, setNextStepLabel, user, errors]);

  return (
    <>
      <Helmet>
        <title>{currentRole?.title} Registration</title>
      </Helmet>
      <View data-node="account-wrapper" as="div">
        <View as="div" margin="large 0 large 0" minWidth={minWidth}>
          <div>
            <input type="hidden" name="user[user_type]" value={user.userType} />
            {user.userType && user.userType !== 'other' && (
              <div>
                <TextField
                  data-node="first_name_field"
                  placeholder="Enter first name"
                  name="user[first_name]"
                  type="text"
                  variant="filled"
                  label="First Name"
                  value={user.firstName}
                  onChange={onChange('firstName')}
                  helperText="Required *"
                  id="firstName"
                  autoComplete="given-name"
                  fullWidth
                />
                <TextField
                  data-node="last_name_field"
                  placeholder="Enter last name"
                  name="user[last_name]"
                  type="text"
                  variant="filled"
                  label="Last Name"
                  value={user.lastName}
                  onChange={onChange('lastName')}
                  helperText="Required *"
                  id="lastName"
                  autoComplete="family-name"
                  fullWidth
                />
                {showEmail && (
                  <TextField
                    data-node="user_email_field"
                    placeholder="Enter email address"
                    name="user[email]"
                    type="email"
                    variant="filled"
                    label="Email Address"
                    value={user.email}
                    onChange={onChange('email')}
                    error={!isBlank(errors.email)}
                    helperText={(!isBlank(errors.email) && errors.email) || 'Required *'}
                    id="email"
                    autoComplete="email"
                    fullWidth
                  />
                )}
                {showPassword && <IUPasswordFields submitButton={false} />}
                {errors.base && <Text>{errors.base}</Text>}
              </div>
            )}
          </div>
        </View>
      </View>
    </>
  );
};

export default AccountCreation;
