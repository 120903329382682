import React from 'react';
import { Modal } from '@instructure/ui-modal';
import { View, ViewTextAlign } from '@instructure/ui-view';
// eslint-disable-next-line import/no-unresolved
import { ModalProps } from '@instructure/ui-modal/types';
import { Heading } from '@instructure/ui-heading';
import { Button, CloseButton } from '@instructure/ui-buttons';

interface IUModalProps extends ModalProps {
  title?: string;
  titleClassName?: string;
  button?: boolean;
  buttonText?: string;
  buttonAlign?: 'left' | 'center' | 'right';
  headerTabContent?: string;
  customStyle?: string;
  noPadding?: boolean;
  headerButtons?: JSX.Element;
  onClose(): void;
}

const IUModal = ({
  children,
  title = '',
  onClose,
  button = true,
  buttonText = 'Ok',
  buttonAlign = 'right',
  open,
  headerTabContent,
  noPadding,
  headerButtons,
  ...rest
}: IUModalProps) => {
  let mappedButtonAlign: ViewTextAlign;
  if (buttonAlign === 'left') {
    mappedButtonAlign = 'start';
  } else if (buttonAlign === 'center') {
    mappedButtonAlign = 'center';
  } else {
    mappedButtonAlign = 'end';
  }

  return (
    <div data-node="modal_window">
      <Modal onClose={onClose} open={open} {...rest}>
        <Modal.Header>
          {headerTabContent}
          {title ? (
            <div>
              <Heading data-node="modal-title">{title}</Heading>
              <div data-node="modal_header_buttons">
                {headerButtons}
                <CloseButton placement="end" offset="small" onClick={onClose} screenReaderLabel="close" />
              </div>
            </div>
          ) : (
            <div>
              <div data-node="modal_header_buttons">
                {headerButtons}
                <CloseButton placement="end" offset="small" onClick={onClose} screenReaderLabel="close" />
              </div>
            </div>
          )}
        </Modal.Header>
        <Modal.Body padding={noPadding ? 'none' : 'medium'}>{children}</Modal.Body>
        <Modal.Footer>
          <View as="div" textAlign={mappedButtonAlign} margin="none none none none">
            {button && (
              <div>
                {/* @ts-expect-error */}
                <Button onClick={onClose} id="modal-close-button" data-node="ok_button">
                  {buttonText}
                </Button>
              </div>
            )}
          </View>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default IUModal;
