import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Flex } from '@instructure/ui-flex';
import { View } from '@instructure/ui-view';
import { Text } from '@instructure/ui-text';
import { useResponsiveContext } from 'hooks/useResponsiveContext';
import IUButton from 'components/IUButton';
import ConfirmationsRepository from '../../../repositories/ConfirmationsRepository';
import * as Routes from '../../../Routes';
import { authenticityToken } from '../../../utils/FetchHelpers';
import IUModal from '../../../components/IUModal';

interface IUEmailConfirmationProps {
  email: string;
  isPartner?: boolean;
}

const IUEmailConfirmation = ({ email, isPartner }: IUEmailConfirmationProps) => {
  const [showModal, updateShowModal] = useState(false);
  const { isMobile } = useResponsiveContext();
  const padding = isMobile ? '10em' : '13em';

  return (
    <>
      <Helmet>
        <title>Check your email</title>
      </Helmet>
      <input type="hidden" name="authenticity_token" value={authenticityToken()} />
      <Flex
        data-node="parent"
        as="div"
        direction="column"
        justifyItems="center"
        height={`calc(100vh - ${padding})`}
        width="100%"
      >
        {/* @ts-expect-error */}
        <Flex.Item shouldShrink shouldGrow>
          <View as="div" margin="0 0">
            <Text weight="bold" size="x-large">
              Check your email
            </Text>
          </View>
          <View as="div" margin="large none none none">
            We sent a confirmation email to:
          </View>
          <View as="div" margin="medium none none none">
            {email}
          </View>
          <View as="div" margin="medium none none none">
            Check your email and click on the confirmation link to continue.
          </View>
          <View as="div" margin="medium none none none">
            Be sure to check your spam folder if you do not receive an email in the next 5 minutes!
          </View>
          {/* @ts-expect-error */}
        </Flex.Item>
        {/* @ts-expect-error */}
        <Flex.Item>
          <Flex as="div" margin="none" direction="row">
            {/* @ts-expect-error */}
            <Flex.Item shouldShrink shouldGrow>
              <IUButton
                onClick={() => {
                  ConfirmationsRepository.create({ email }).then(() => {
                    updateShowModal(true);
                  });
                }}
                color="secondary"
                margin="none none none xx-small"
                size="medium"
              >
                Resend Email
              </IUButton>
              {/* @ts-expect-error */}
            </Flex.Item>
            {/* @ts-expect-error */}
            <Flex.Item>
              <>
                <IUButton
                  href={isPartner ? Routes.new_partner_session_path() : Routes.new_user_session_path()}
                  interaction="enabled"
                  color="primary"
                  margin="xx-small"
                  size="medium"
                  type="submit"
                >
                  Sign In
                </IUButton>
              </>
              {/* @ts-expect-error */}
            </Flex.Item>
          </Flex>
          {/* @ts-expect-error */}
        </Flex.Item>
      </Flex>
      {showModal && (
        <IUModal
          onClose={() => {
            updateShowModal(false);
          }}
          title="Check your email"
          label="Please check your email, we have resent the email."
          open={showModal}
          buttonAlign="center"
          data-node="resend-email-modal"
        >
          <View as="div" maxWidth="45em">
            An email has been re-sent to <Text weight="bold">{email}</Text>. Please make sure to check your spam folder
            if you do not receive an email in the next 5 minutes.
          </View>
        </IUModal>
      )}
    </>
  );
};

export default IUEmailConfirmation;
