import React, { useEffect, useState } from 'react';
import { Text } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import { Helmet } from 'react-helmet';
import { Flex } from '@instructure/ui-flex';
import { Button } from '@instructure/ui-buttons';
import IURoleRender from '../../../sharedComponents/IURoleRender';
import { authenticityToken } from '../../../../../utils/FetchHelpers';

interface SelectRoleProps {
  onChangeRole: (role: string) => void;
  loginPath: string;
  registrationPath: string;
  disabled?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
}

const SelectRole: React.FC<SelectRoleProps> = ({
  isMobile,
  isTablet,
  onChangeRole,
  loginPath,
  registrationPath,
  disabled = false,
}) => {
  const [nextStepAllowed, setNextStepAllowed] = useState(false);
  const [nextStepLabel, setNextStepLabel] = useState('Next');
  const [localRole, setLocalRole] = useState<string | null>(null);

  useEffect(() => {
    setNextStepAllowed(!!localRole);
    setNextStepLabel(localRole === 'product_developer' ? 'Redirect to Partner Registration' : 'Next');
  }, [localRole, setNextStepAllowed, setNextStepLabel]);

  const padding = isMobile ? '9em' : '13em';

  const updatedRegistrationPath = `${registrationPath}?primary_role=${localRole}&is_partner=true`;

  const handleButtonClick = e => {
    if (localRole !== 'product_developer') {
      e.preventDefault();
      onChangeRole(localRole || '');
    }
  };

  // @ts-ignore
  return (
    <>
      <Helmet>
        <title>Select Role</title>
      </Helmet>

      <Flex
        data-node="parent"
        as="div"
        direction="column"
        justifyItems="center"
        height={isTablet && !isMobile ? undefined : `calc(100vh - ${padding})`}
      >
        {/* @ts-expect-error */}
        <Flex.Item data-node="bhee" shouldShrink shouldGrow>
          <View as="div" minHeight="32em">
            <View as="div" margin="0 0">
              <Text weight="bold" size="x-large">
                Select Role
              </Text>
            </View>
            <View as="div" margin="large 0 large 0">
              <Text>Please select the role that best describes your responsibilities at your organization.</Text>
            </View>
            <IURoleRender
              selectedRole={localRole}
              onChangeRole={(role: string) => {
                setLocalRole(role);
                return role;
              }}
              disabled={disabled}
            />
          </View>
          {/* @ts-expect-error */}
        </Flex.Item>
        {/* @ts-expect-error */}
        <Flex.Item>
          <Flex as="div" margin="none" direction="row">
            {/* @ts-expect-error */}
            <Flex.Item shouldShrink shouldGrow>
              <Button href={loginPath} color="secondary" margin="none none none xx-small" size="medium">
                Cancel
              </Button>
              {/* @ts-expect-error */}
            </Flex.Item>
            {/* @ts-expect-error */}
            <Flex.Item>
              <form action={updatedRegistrationPath} method="post" onSubmit={handleButtonClick}>
                <input type="hidden" name="authenticity_token" value={authenticityToken()} />
                <Button
                  type="submit"
                  data-node="submit_role"
                  interaction={!nextStepAllowed ? 'disabled' : 'enabled'}
                  color="primary"
                  margin="xx-small"
                  size="medium"
                >
                  {nextStepLabel}
                </Button>
              </form>
              {/* @ts-expect-error */}
            </Flex.Item>
          </Flex>
          {/* @ts-expect-error */}
        </Flex.Item>
      </Flex>
    </>
  );
};

export default SelectRole;
