/*
  This is a wrapper component that will render the new login page if the feature flag is enabled, otherwise it will render the old login page.
  When the feature flag is to be removed, remove this component and the MUSignUp component and renaming the IUSignUp component to SignUp.
 */

import React from 'react';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import IUSignUp from '../IUSignUp';
import MUSignUp from '../MUSignUp';
import type { User } from '../../../presenters/UserPresenter';

const SignUpPage = ({ user }: IUSignUpPageProps) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  const { isPartner } = gon;
  if (useNewLogin || isPartner) return <IUSignUp user={user} />; // isPartner={isPartner}
  return <MUSignUp user={user} />;
};

interface IUSignUpPageProps {
  user: User | null;
}

export default SignUpPage;
