import React, { useState } from 'react';
import { head, last } from 'ramda';
import { Alert } from '@instructure/ui-alerts';

interface IUFlashProps {
  flash: string[];
  onClose?: () => void;
  toast?: boolean;
}

const IUFlash = ({ flash, onClose = () => {}, toast }: IUFlashProps) => {
  const variants = {
    alert: 'error', // legacy, use error
    error: 'error',
    success: 'success',
    info: 'info',
    warning: 'warning',
    notice: 'warning', // legacy, use warning
  };
  const [flashType] = useState(head(flash));
  const [message] = useState(last(flash) || '');
  const additional: { timeout?: number; renderCloseButtonLabel?: string } = {};

  if (toast) {
    additional.timeout = 5000;
  } else {
    additional.renderCloseButtonLabel = 'Close';
  }
  if (message.length > 0) {
    return (
      <Alert data-node="flash_alert" variant={variants[flashType || 'info']} onDismiss={onClose} {...additional}>
        <span id="client-snackbar">{message}</span>
      </Alert>
    );
  }
  return null;
};

export default IUFlash;
