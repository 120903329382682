import React from 'react';
import { View } from '@instructure/ui-view';
import { Spinner } from '@instructure/ui-spinner';

const Loader = () => {
  return (
    <View as="div" data-node="loader" textAlign="center" padding="large">
      <Spinner size="large" />
    </View>
  );
};

export default Loader;
