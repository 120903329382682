import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-use';
import queryString from 'query-string';
import { View } from '@instructure/ui-view';
import { Text } from '@instructure/ui-text';
import { Flex } from '@instructure/ui-flex';
import { isEmpty, join } from 'ramda';
import IUButton from 'components/IUButton';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';
import { camelize } from 'utils/keysConverter';
import Loader from 'components/IULoader';
import { useResponsiveContext } from 'hooks/useResponsiveContext';
import Flash from 'components/IUFlash';
import IUPasswordFields from '../sharedComponents/IUPasswordFields';
import IURoleSelector from '../sharedComponents/IURoleSelector';

interface IUProfileSetupProps {
  errors: string[];
  user: Record<string, any>;
}

const IUProfileSetup: React.FC<IUProfileSetupProps> = ({ errors, user }) => {
  const parsedQueryString = queryString.parse(useLocation().search || '');
  const redirect = (parsedQueryString.redirect_back || '') + (useLocation().hash || '');
  const anchor = useLocation().hash;
  const [userFields, updateUserFields] = useState(camelize(user));
  const { isMobile, isTablet } = useResponsiveContext();

  const handleChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    updateUserFields({ ...userFields, [name]: e.target.value });
  };

  const handleChangeRole = (role: string) => {
    updateUserFields({ ...userFields, userType: userFields.userType !== role ? role : '' });
  };

  let title: string | null;
  let description: string | null;
  let formFields: React.ReactNode | null;
  let showBackButton = false;
  const padding = isMobile ? '10em' : '13em';
  const validUserType = userFields.userType && userFields.userType !== '';

  switch (userFields.signUpState) {
    case 'password_setup':
      title = 'Set Your Password';
      description =
        'Welcome back. To complete this process, type in a new password of your choice. The following criteria applies when selecting your new password:';
      formFields = (
        <IUPasswordFields backToLogin={false} submitButton panelMinHeight="32em" buttonText="Set Password" />
      );
      break;
    case 'role_setup':
    case 'renew_role':
      title = !validUserType ? 'Select Your Role' : 'Registration';
      description = !validUserType
        ? 'Please select the role that best describes your responsibilities at your organization.'
        : 'Create your account';
      formFields = (
        <IURoleSelector
          user={userFields}
          buttonText="Continue"
          onChange={handleChange}
          onChangeRole={handleChangeRole}
          disabled={!userFields.canChangeUserType}
        />
      );
      showBackButton = userFields.canChangeUserType && userFields.userType && userFields.userType !== 'other';
      break;
    default:
      title = !validUserType ? 'Select Your Role' : 'Registration';
      description = !validUserType
        ? 'Please select the role that best describes your responsibilities at your organization.'
        : 'Create your account';
      formFields = (
        <IURoleSelector
          showPassword
          user={userFields}
          buttonText="Continue"
          onChange={handleChange}
          onChangeRole={handleChangeRole}
          disabled={!userFields.canChangeUserType}
        />
      );
      showBackButton = userFields.canChangeUserType && userFields.userType && userFields.userType !== 'other';
  }
  if (!title || !description || !formFields) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {showBackButton && <IUButton onClick={() => handleChangeRole('')}>Back</IUButton>}
      <form action={Routes.profile_path({ redirect_back: redirect, anchor })} method="post">
        <input type="hidden" name="authenticity_token" value={authenticityToken()} />
        <input type="hidden" name="_method" value="patch" />
        <Flex
          data-node="parent"
          as="div"
          direction="column"
          justifyItems="center"
          height={isTablet && !isMobile ? undefined : `calc(100vh - ${padding})`}
        >
          {/* @ts-ignore */}
          <Flex.Item shouldShrink>
            <View as="div" margin="none">
              <Text weight="bold" size="x-large">
                {title}
              </Text>
              <View padding="large none none none" as="div">
                {!isEmpty(errors) && <Flash flash={['alert', join(', ', errors)]} />}
                {description}
              </View>
            </View>
            {/* @ts-ignore */}
          </Flex.Item>
          {formFields}
        </Flex>
      </form>
    </>
  );
};

export default IUProfileSetup;
