import React from 'react';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import IUProfileSetup from '../IUProfileSetup';
import MUProfileSetup from '../MUProfileSetup';

interface ProfileSetupProps {
  errors: string[];
  user: {
    signUpState: string;
    userType: string;
    canChangeUserType: boolean;
    [key: string]: any;
  };
}

const ProfileSetup = ({ errors, user }: ProfileSetupProps) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  const { isPartner } = gon;
  if (useNewLogin || isPartner) return <IUProfileSetup errors={errors} user={user} />; // isPartner={isPartner}
  return <MUProfileSetup errors={errors} user={user} />;
};

export default ProfileSetup;
