import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from '@instructure/ui-breadcrumb';
import { View } from '@instructure/ui-view';

const IUBreadcrumb = ({ items, dataNode }) => {
  return (
    <View as="div" padding="none none large none">
      <Breadcrumb data-node={dataNode || 'breadcrumb'}>
        {items.map(item => {
          const props = { href: item.href || item.to, onClick: item.onClick };
          return (
            <Breadcrumb.Link key={item.label} {...props}>
              {item.node || item.label}
            </Breadcrumb.Link>
          );
        })}
      </Breadcrumb>
    </View>
  );
};

IUBreadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      onClick: PropTypes.func,
      href: PropTypes.string,
      label: PropTypes.node.isRequired,
      node: PropTypes.node,
    }),
  ).isRequired,
  dataNode: PropTypes.string,
};

export default IUBreadcrumb;
