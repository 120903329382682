import React, { useRef } from 'react';
import { View } from '@instructure/ui-view';
import { TextInput, TextInputProps } from '@instructure/ui-text-input';

type Props = TextInputProps & {
  fullWidth?: boolean | null | undefined;
  autoComplete?: string | null | undefined;
  variant?: string | null | undefined;
  name?: string | null | undefined;
  error?: boolean | undefined;
  helperText?: string;
  maxLength?: number | null | undefined;
};

const TextField: React.FC<Props> = ({
  maxLength,
  helperText,
  value,
  onChange,
  error,
  fullWidth,
  label,
  autoComplete,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  // Note: I don't like doing this, but don't see another option at the moment.
  const setInputRef = (element: HTMLInputElement) => {
    inputRef.current = element;
    if (element && autoComplete) {
      element.setAttribute('autocomplete', autoComplete);
    }
  };

  const showMaxLength = maxLength! > 0 && typeof value === 'string';

  return (
    <View as="div" margin="medium xx-small xx-small xx-small">
      {showMaxLength ? (
        <TextInput
          renderLabel={label}
          inputRef={setInputRef}
          display={fullWidth != null && fullWidth ? 'block' : 'inline-block'}
          value={value}
          renderAfterInput={<>{`${value.length}/${maxLength}`}</>}
          onChange={
            onChange &&
            (e => {
              if (e.target.value.length > maxLength!) {
                e.target.value = e.target.value.substring(0, maxLength);
              }
              return onChange(e);
            })
          }
          messages={error ? [{ text: helperText, type: 'error' }] : []}
          {...rest}
        />
      ) : (
        <TextInput
          renderLabel={label}
          inputRef={setInputRef}
          display={fullWidth != null && fullWidth ? 'block' : 'inline-block'}
          value={value}
          onChange={onChange}
          messages={error ? [{ text: helperText, type: 'error' }] : []}
          {...rest}
        />
      )}
    </View>
  );
};

export default TextField;
