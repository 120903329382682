import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { pluck, includes, isEmpty } from 'ramda';
import queryString from 'query-string';
import { Flex } from '@instructure/ui-flex';
import { View } from '@instructure/ui-view';
import { Button } from '@instructure/ui-buttons';
import { Text } from '@instructure/ui-text';
import type { User } from 'presenters/UserPresenter';

import Form from 'forms/SignUp/SignUpForm';
import { set, isBlank } from 'utils/Utils';
import { useResponsiveContext } from 'hooks/useResponsiveContext';
import { authenticityToken } from 'utils/FetchHelpers';
import roles from '../sharedComponents/IURoleRender/roles';
import Pardot from '../../../services/Pardot';
import * as Routes from '../../../Routes';
import Breadcrumbs from '../../../components/IUBreadcrumbs';
import SelectRole from './components/SelectRole';
import AccountCreation from './components/AccountCreation';

interface IUSignUpPageProps {
  user: User | null;
}

const IUSignUpPage = ({ user }: IUSignUpPageProps) => {
  const { isPartner } = gon;
  const { isMobile, isTablet } = useResponsiveContext();

  const [form, setForm] = useState(Form.defaultAttributes(user || {}));

  const [nextStepLabel, setNextStepLabel] = useState('Submit');
  const [nextStepAllowed, setNextStepAllowed] = useState(true);
  const disabled = false;
  const [errors, setErrors] = useState(user?.errors || {});
  const [selectedRole, setSelectedRole] = useState<string | null>(user?.userType || null);

  const setPrimaryRole = (primaryRole: string | string[] | null) => {
    if (isBlank(primaryRole)) {
      return;
    }
    const roleIds = pluck('id', roles);
    if (includes(primaryRole, roleIds)) {
      handleRoleChange(primaryRole as string);
    }
  };

  const loginPath = isPartner ? Routes.new_partner_session_path() : Routes.new_user_session_path();
  const registrationPath = Routes.new_user_registration_path();

  useEffect(() => {
    const { search } = window.location;
    const primaryRole = (queryString.parse(search).primary_role || '').toString();
    setPrimaryRole(primaryRole);
  }, []);

  useEffect(() => {
    if (isPartner) {
      setPrimaryRole('product_developer');
    }
  }, [isPartner]);

  const handleRoleChange = (role: string) => {
    const newState = set(form, ['userType'], role);
    setSelectedRole(role);
    setForm(newState);
    return role;
  };

  const handleSubmit = (e: React.FormEvent) => {
    const formErrors = Form.validate(form);
    if (isEmpty(formErrors)) {
      const params = Form.attributesToSubmit(form);
      Pardot.signUp(params);
    } else {
      e.preventDefault();
      setErrors(formErrors);
    }
  };

  const currentRole = roles.find(role => role.id === selectedRole);
  const padding = isMobile ? '13em' : '13em';

  if (!selectedRole) {
    return (
      <SelectRole
        isMobile={isMobile}
        isTablet={isTablet}
        onChangeRole={handleRoleChange}
        disabled={disabled}
        loginPath={loginPath}
        registrationPath={registrationPath}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Sign Up | Select Your Role</title>
      </Helmet>
      <>
        <form action={Routes.user_registration_path()} method="post" onSubmit={handleSubmit}>
          <input type="hidden" name="authenticity_token" value={authenticityToken()} />
          <Flex
            data-node="parent"
            as="div"
            direction="column"
            justifyItems="center"
            height={isTablet && !isMobile ? undefined : `calc(100vh - ${padding})`}
          >
            {/* @ts-expect-error */}
            <Flex.Item shouldShrink shouldGrow>
              <View as="div" margin="none" minHeight="32em">
                <Breadcrumbs
                  items={[
                    {
                      label: 'Select Role',
                      onClick: () => setSelectedRole(null),
                    },
                    {
                      label: isMobile ? `Registration` : `${currentRole?.title} Registration`,
                      to: '',
                    },
                  ]}
                />
                <View as="div" margin="0 0">
                  <Text weight="bold" size="x-large">
                    {currentRole?.title} Registration
                  </Text>
                </View>
                <AccountCreation
                  selectedRole={selectedRole}
                  setIsNextStepAllowed={setNextStepAllowed}
                  setNextStepLabel={setNextStepLabel}
                  user={form}
                  onChange={(name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
                    const newState = set(form, [name], e.target.value);
                    setForm(newState);
                  }}
                  errors={errors}
                  showEmail
                />
              </View>
              {/* @ts-expect-error */}
            </Flex.Item>
            {/* @ts-expect-error */}
            <Flex.Item>
              <Flex as="div" margin="none" direction="row">
                {/* @ts-expect-error */}
                <Flex.Item shouldShrink shouldGrow>
                  <Button href={loginPath} color="secondary" margin="none none none xx-small" size="medium">
                    Cancel
                  </Button>
                  {/* @ts-expect-error */}
                </Flex.Item>
                {/* @ts-expect-error */}
                <Flex.Item>
                  <>
                    <Button
                      data-node="submit_button"
                      interaction={!nextStepAllowed ? 'disabled' : 'enabled'}
                      color="primary"
                      margin="xx-small"
                      size="medium"
                      type="submit"
                    >
                      {nextStepLabel}
                    </Button>
                  </>
                  {/* @ts-expect-error */}
                </Flex.Item>
              </Flex>
              {/* @ts-expect-error */}
            </Flex.Item>
          </Flex>
        </form>
      </>
    </>
  );
};

export default IUSignUpPage;
