import React from 'react';
import { ButtonProps, Button } from '@instructure/ui-buttons';

/**
 * Note: This is not a drop in replacement of the Button component.
 * It is a wrapper around the Button component, that ignores the onClick prop, with ts-ignore
 */

interface IUButtonProps extends ButtonProps {
  onClick?: () => void;
}

const IUButton = ({ onClick, ...rest }: IUButtonProps) => {
  // @ts-ignore
  return <Button onClick={onClick} {...rest} />;
};

export default IUButton;
