import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@instructure/ui-flex';
import { List } from '@instructure/ui-list';
import { View } from '@instructure/ui-view';
import { useResponsiveContext } from 'hooks/useResponsiveContext';
import Role from './components/Role';
import roles from './roles';

const IURoleRender = ({ selectedRole, onChangeRole, disabled = false }) => {
  const handleSelect = useCallback(
    value => {
      onChangeRole(value);
    },
    [onChangeRole],
  );

  const { isMobile } = useResponsiveContext();
  const maxWidth = isMobile ? '20em' : '34em';

  const renderRoles = useCallback(
    roleItems => {
      return roleItems.map(role => (
        // <View as="div" width="12em">
        <Flex.Item key={role.id}>
          <Role
            data-node="role_section"
            id={role.id}
            title={role.title}
            selected={selectedRole === role.id}
            onSelect={handleSelect}
            disabled={disabled}
          />
        </Flex.Item>
        // </View>
      ));
    },
    [handleSelect],
  );

  const theRole = roles.find(role => role.id === selectedRole);

  return (
    <>
      <Flex justifyItems="space-between" margin={isMobile ? 'none none small none' : 'none none large none'}>
        {renderRoles(roles)}
      </Flex>
      {theRole && (
        <View as="div" maxWidth={maxWidth}>
          <List size="medium" margin="medium none none none">
            {theRole.descriptions.map(description => (
              <List.Item margin="small none none none">{description}</List.Item>
            ))}
          </List>
        </View>
      )}
    </>
  );
};

IURoleRender.propTypes = {
  selectedRole: PropTypes.string,
  onChangeRole: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default IURoleRender;
